import React from 'react';

function PracticeAreas() {
  return (
    <div className="section-pale pt-5 pb-5 pt-md-10 pb-md-10">
      <div className="container">
        <h2 className="text-mega text-grey mb-6 mb-md-8">Practice areas</h2>
        <div className="row">
          <div className="col-lg-6 mb-4 mb-lg-5">
            <div className="container-service">
              <a href="/investor-visa-usa-immigration">
                <span className="part-header is-investor mb-7 mb-md-6">
                  <span className="text-display">
                    Investor
                    <br />
                    Immigration
                  </span>
                </span>
              </a>
              <span className="separator mb-7 mb-md-6" />
              <a href="/investor-visa-usa-immigration">
                <span className="text-lead mb-4">
                  <strong>
                    Do you wish to immigrate to the United States through investment?
                  </strong>
                </span>

                <span className="text-lead">
                  Our immigration lawyer represents you throughout the entire investor
                  visa process, from initial investment planning, transaction structuring,
                  contract drafting, to your investor visa application. As an experienced
                  investor immigration law firm, we regularly handle EB-5 Green Cards, E-2
                  Visa, and L-1 Visa cases.
                </span>
              </a>
            </div>
          </div>
          <div className="col-lg-6 mb-4 mb-lg-5">
            <div className="container-service">
              <a href="/employment-based-visa-immigration">
                <div className="part-header is-employment mb-7 mb-md-6">
                  <span className="text-display">
                    Employment-Based
                    <br />
                    Immigration
                  </span>
                </div>
              </a>
              <a href="/employment-based-visa-immigration">
                <span className="separator mb-7 mb-md-6" />
                <span className="text-lead mb-4">
                  <strong>
                    Are you seeking to hire a foreign national or looking to apply for a
                    business visa?
                  </strong>
                </span>
                <span className="text-lead">
                  Our immigration lawyer helps foreign nationals secure business visas
                  while ensuring the US employer complies with all laws and regulations.
                  As an experienced business immigration law firm, we regularly handle{' '}
                  H-1B Visa, E-2 Visa, L-1 Visa, TN Visa, and O-1 Visa as well as EB-1,
                  EB-2, and EB-3 Green Card cases.
                </span>
              </a>
            </div>
          </div>
          <div className="col-lg-6 mb-4 mb-lg-0">
            <div className="container-service">
              <a href="/family-based-green-card-immigration">
                <div className="part-header is-individual mb-7 mb-md-6">
                  <span className="text-display">
                    Individual &amp; Family
                    <br />
                    Immigration
                  </span>
                </div>
              </a>
              <a href="/family-based-green-card-immigration">
                <span className="separator mb-7 mb-md-6" />
                <span className="text-lead mb-4">
                  <strong>
                    Do you have an immigration issue for which you are seeking an
                    experienced attorney?
                  </strong>
                </span>
                <span className="text-lead">
                  Our immigration law firm advises on various individual and family
                  immigration matters. Our immigration lawyer represents individuals in US
                  Citizenship, K-1 Fiance Visa, Removal of Conditions, Case Delay
                  Litigation, Waiver of Inadmissibility, Immigration Appeal , and EB-1,
                  EB-2 NIW, and Family-Based Green Card cases.
                </span>
              </a>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="container-service">
              <a href="/business-law-services">
                <span className="part-header is-business mb-7 mb-md-6">
                  <h3 className="text-display">
                    Business
                    <br />
                    Law
                  </h3>
                </span>
              </a>
              <span className="separator mb-7 mb-md-6" />
              <a href="/business-law-services">
                <span className="text-lead mb-4">
                  <strong>
                    Are you seeking legal representation regarding a business transaction
                    or corporate matter?{' '}
                  </strong>
                </span>
                <span className="text-lead">
                  Our business lawyer provides strategic advice and representation to help
                  you achieve your business objectives. As an experienced business law
                  firm, we regularly advise on company formation &amp; startup solutions,
                  domestic &amp; cross-border transactions, and customs &amp;
                  international trade law issues.
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PracticeAreas;
