import React from 'react';
import { Link } from 'gatsby';
import Hero from '../components/common/hero';
import Layout from '../components/layout';
import PracticeAreas from '../components/home/practice-areas';
import CtaDeck from '../components/common/cta-deck';
import NewsInsights from '../components/home/News-insights';
import SuccessStoriesDeck from '../components/common/Success-stories-deck';
import { alternateLinks } from '../data/site-data';
import AbTestingDeck from '../components/ab-testing/ab-testing-deck';

const headMeta = {
  title: 'Pandev Law | Immigration Lawyers USA | Immigration Law Firm',
  description:
    'We have vast experience in Investor Immigration, Employment-Based Immigration, Family-Based Immigration, and Business Law. Contact us now for a consultation.',
  keywords:
    'immigration law firm USA,immigration lawyers,immigration law services,immigration attorney,immigration legal services',
  alternateLinks,
};

const schema = `{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "Pandev Law - Immigration and startup lawyer firm in USA",
  "url": "https://www.pandevlaw.com/",
  "mainEntity": { "@id": "https://www.pandevlaw.com/" },
  "isPartOf": {
    "@type": "WebSite",
    "name": "Pandev Law",
    "@id": "https://www.pandevlaw.com/#website",
    "url": "https://www.pandevlaw.com/",
    "mainEntity": { "@id": "https://www.pandevlaw.com/about/#person" },
    "publisher": {
      "@type": "Person",
      "name": "Adrian Pandev",
      "url": "https://www.pandevlaw.com/about/",
      "@id": "https://www.pandevlaw.com/about/#person"
    }
  }
}`;

export default function Home({ location }) {
  return (
    <Layout headMeta={headMeta} location={location} footerSchema={schema}>
      <Hero
        title="Your Journey to America Starts Here"
        hasTitleTrademark
        isTitleBgTransparent
        readMoreContent={
          <>
            <br />
            <br />
            Pandev Law prioritizes client service and satisfaction above all else. We
            place our clients at the center of our legal practice and have made it our
            mission to provide cost-effective, efficient, and transparent legal solutions.
            Our business and immigration lawyer has a passion for pursuing our clients’
            objectives and takes pride in developing strong relationships with our
            clients. As an experienced business and immigration law firm, we help you
            understand your legal options and strive to see you and your business
            flourish.
          </>
        }>
        <>
          <br className="text-lead mb-5 mb-md-6" />
          <strong>Pandev Law</strong> is a business and immigration law firm dedicated to
          providing exceptionally high quality representation. The motto, &ldquo;Your
          journey to America starts here&rdquo; says it all.
          <br />
          <br />
          We focus on helping international companies and individuals move to the United
          States. Pandev Law is uniquely positioned to effectively represent individual
          and corporate clients throughout the United States and worldwide. Our business
          and immigration lawyer speaks <Link to="/russian/">Russian</Link>,{' '}
          <Link to="/german/">German</Link>, <Link to="/hungarian/">Hungarian</Link>,{' '}
          <Link to="/spanish/">Spanish</Link>, and <Link to="/bulgarian/">Bulgarian</Link>{' '}
          fluently and would love to advise you in your native language.{' '}
        </>
      </Hero>

      <AbTestingDeck position="top" greyBg={false} />

      <PracticeAreas />

      <NewsInsights />

      <AbTestingDeck />
      <SuccessStoriesDeck />

      <CtaDeck />
    </Layout>
  );
}
