import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PostsSlider from './Posts-slider';

function SuccessStoriesDeck({ posts }) {
  const data = useStaticQuery(graphql`
    query SuccessStoriesSliderQuery {
      posts: allWpPost(
        limit: 9
        sort: { date: DESC }
        filter: { categories: { nodes: { elemMatch: { databaseId: { eq: 4 } } } } }
      ) {
        edges {
          node {
            id: databaseId
            title
            date
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 500
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
                altText
              }
            }
            slug
            excerpt
          }
        }
      }
    }
  `);

  return (
    <PostsSlider
      title="Success Stories"
      posts={posts || data.posts}
      seeMoreLink="/success-stories/"
      seeMoreText="See more success stories"
    />
  );
}

export default SuccessStoriesDeck;
