import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import CommonCard from '../common/common-card';
import { createBlogLink } from '../../utils';

function NewsInsights() {
  const data = useStaticQuery(graphql`
    query NewsInsightsQuery {
      allWpPost(limit: 3, sort: { date: DESC }) {
        edges {
          node {
            id: databaseId
            title
            date
            slug
            excerpt
          }
        }
      }
    }
  `);

  return (
    <div className="section pt-5 pb-5 pt-md-10 pb-md-10">
      <div className="container">
        <h2 className="text-mega text-grey mb-5 mb-md-8">News &amp; Insights</h2>
        <div className="row" id="latestPosts">
          {data.allWpPost.edges.map((edge, index) => (
            <CommonCard
              key={index}
              title={edge.node.title}
              date={edge.node.date}
              description={edge.node.excerpt}
              link={createBlogLink(edge.node.slug)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default NewsInsights;
